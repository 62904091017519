<div class="add-deposit-block">
  <div class="add-deposit">
    <div class="item seleted-block">
      <label>Мерчант <span *ngIf="loadingMerchants">loading...</span></label>
      <select
        [(ngModel)]="selectedMerchantId"
        [ngModelOptions]="{ standalone: true }"
      >
        <option *ngFor="let option of merchants; let i = index" [value]="i">
          {{ option.name }} ({{ option.balance }})
        </option>
      </select>
    </div>

    <div>
      <div class="item">
        <label>Сумма USDT</label>
        <input
          [(ngModel)]="data.amount"
          type="number"
          placeholder="Сумма зачисления"
        />
      </div>

      <div class="item">
        <label
          >Текущий курс
          <span (click)="getRate()" *ngIf="!loadingRate">
            <img src="/assets/svg/update.svg" width="10px" fill="#fff"
          /></span>
          <span *ngIf="loadingRate" style="background: red">
            <img src="/assets/svg/update.svg" width="10px" fill="#fff"
          /></span>
        </label>
        <input
          [(ngModel)]="data.rate"
          type="number"
          placeholder="Текущий курс"
        />
      </div>

      <div class="itog">
        <span>{{ amountDeposit.toFixed(2) }}</span>
      </div>

      <button (click)="sendDeposit()" *ngIf="!loadingSend && !sendDone">
        Пополнить мерчанта
      </button>
      <button *ngIf="loadingSend">Loading</button>
    </div>
    <button *ngIf="sendDone" style="background: green; color: #fff">
      Успешно
    </button>
  </div>

  <div class="block-deposits">
    <table class="table">
      <thead>
        <tr>
          <td>ID</td>
          <td>Merchant ID</td>
          <td>Name</td>
          <td>Процент</td>
          <td>Баланс</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of merchants" [routerLink]="['/merchants/', item.id]">
          <td>{{ item.id }}</td>
          <td>{{ item.merchant_id }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.percent }}</td>
          <td>{{ item.balance }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
