import { Component, Input, OnInit } from "@angular/core";

import { date, txHash } from "@environments/helper";
import { ActivatedRoute, Router } from "@angular/router";

import { OtherApiService } from "@services/api.service";

@Component({
  selector: "app-insurance-deposit",
  templateUrl: "./trader.component.html",
  styleUrls: ["../body.css"],
})
export class Trader implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private otherApiService: OtherApiService
  ) {}

  date = date;
  traderId = 0;
  banHours = 0;
  banMinutes = 0;
  loadingTrader = true;
  trader: any = null;
  referrals: any = [];
  feeReferrals: any = [];
  loadingReferrals = true;
  loadingFeeReferrals = true;
  loadingDeleteReferral = false;
  isShowReferrals = false;
  loadingEditTrader = false;
  loadingBanTrader = false;
  showBanTrader = false;
  showLimit = false;
  JSON = JSON;
  limitsKeys: any = [];
  limits: any = [];
  limitsMap: any = [];
  saved: any = false;

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.traderId = Number(params["id"]);
      this.getTrader();
      this.getReferralsTrader();
      this.getFeeReferralsTrader();
    });
  }

  async getTrader() {
    this.loadingTrader = true;
    const data = await this.otherApiService.getAdmin("get-trader", {
      trader_id: this.traderId,
    });
    this.loadingTrader = false;
    if ((data as any).success) {
      this.trader = (data as any).result;
      this.limits = JSON.parse(this.trader.limits);
      this.limitsKeys = Object.keys(this.limits);
      this.limitsMap = JSON.parse(this.trader.map_limit);
    }
  }
  async getReferralsTrader() {
    this.loadingReferrals = true;
    const data = await this.otherApiService.getAdmin("get-referrals-traders", {
      trader_id: this.traderId,
    });

    this.loadingReferrals = false;
    if ((data as any).success) {
      this.referrals = (data as any).result;
    }
  }

  async getFeeReferralsTrader() {
    this.loadingFeeReferrals = true;
    const data = await this.otherApiService.getAdmin(
      "get-fee-referrals-traders",
      {
        trader_id: this.traderId,
      }
    );

    this.loadingFeeReferrals = false;
    if ((data as any).success) {
      this.feeReferrals = (data as any).result;
    }
  }

  async deleteReferral(traderId: any) {
    this.loadingDeleteReferral = true;
    const data = await this.otherApiService.getAdmin("delete-referral-trader", {
      trader_id: traderId,
    });

    this.loadingDeleteReferral = false;
    if ((data as any).success) {
      this.referrals.map((val: any) => {
        if (val.id == traderId) {
          this.feeReferrals.push(val);
        }
      });
      this.referrals = this.referrals.filter((val: any) => val.id !== traderId);
    }
  }

  async addReferral(traderId: any) {
    this.loadingDeleteReferral = true;
    console.log({
      referrer_id: this.traderId,
      referral_id: traderId,
    });

    const data = await this.otherApiService.getAdmin("add-referral-trader", {
      referrer_id: this.traderId,
      referral_id: traderId,
    });

    this.loadingDeleteReferral = false;

    if ((data as any).success) {
      this.feeReferrals.map((val: any) => {
        if (val.id == traderId) {
          this.referrals.push(val);
        }
      });
      this.feeReferrals = this.feeReferrals.filter(
        (val: any) => val.id !== traderId
      );
    }
  }

  async editTrader() {
    this.saved = false;
    this.loadingEditTrader = true;
    this.limits.max_withdrawals_amount = Number(this.limits.max_withdrawals_amount)
    this.trader["limits"] = this.JSON.stringify(this.limits);

    const data = await this.otherApiService.post("edit-trader", {
      ...this.trader,
    });

    this.loadingEditTrader = false;

    if ((data as any).success) {
      this.saved = true;
    }
  }

  async traderBanReset() {
    this.loadingBanTrader = true;
    const data = await this.otherApiService.post("trader-ban-reset", {
      trader_id: this.traderId,
    });

    this.loadingBanTrader = false;

    if ((data as any).success) {
      this.trader.ban_time = 0;
    }
  }
  async addTraderBan() {
    this.loadingBanTrader = true;

    const data = await this.otherApiService.post("add-trader-ban", {
      trader_id: this.traderId,
      hours: this.banHours,
      minutes: this.banMinutes,
    });

    this.loadingBanTrader = false;

    if ((data as any).success) {
      this.showBanTrader = false;
      this.trader.ban_time =
        Date.now() / 1000 + this.banHours * 60 * 60 + this.banMinutes * 60;
    }
  }
}
