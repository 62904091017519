import { Component } from "@angular/core";

import { TabulatorFull as Tabulator } from "tabulator-tables";
import { FormGroup, FormControl } from "@angular/forms";

import { CardsApiService } from "@services/api.service";
import { environment } from "@environments/environment";
import { getCookie } from "@environments/helper";
@Component({
  selector: "app-cards-table",
  templateUrl: "./cards-table.component.html",
  styleUrls: ["./cards-table.component.css"],
})
export class CardsTableComponent {
  cardForm: any = new FormGroup({
    number: new FormControl(),
    balance: new FormControl(),

    name: new FormControl(),
    phone: new FormControl(),
  });

  table: any;

  constructor(private cardsApiService: CardsApiService) {}

  ngOnInit() {
    this.drawTable();
  }

  rowContextMenu = [
    {
      label: "Активировать",
      action: async (e: any, row: any) => {
        const number = row.getCell("number").getValue();

        const success = await this.cardsApiService.activate(number);

        if (success) {
          row.getCell("active").setValue(true);
        }
      },
    },
    {
      label: "Деактивировать",
      action: async (e: any, row: any) => {
        const number = row.getCell("number").getValue();

        const success = await this.cardsApiService.deactivate(number);

        if (success) {
          row.getCell("active").setValue(false);
        }
      },
    },
    {
      label: "Удалить",
      action: async (e: any, row: any) => {
        const number = row.getCell("number").getValue();

        const success = await this.cardsApiService.delete(number);

        if (success) {
          row.delete();
        }
      },
    },
  ];

  private drawTable(): void {
    var div = document.createElement("div");

    this.table = new Tabulator(div, {
      persistence: {
        filter: true,
        sort: true,
      },
      filterMode: "remote",
      sortMode: "remote",
      pagination: true,
      paginationMode: "remote",
      paginationSize: 300,
      paginationSizeSelector: [5, 10, 15, 20, 50, 100, true],
      ajaxURL: environment.apiUrl + "/cards/get",
      ajaxConfig: {
        headers: {
          Authorization: JSON.parse(getCookie("json_token") || "{}")["token"],
        },
      },
      columns: [
        {
          title: "Статус",
          field: "active",
          formatter: "tickCross",
          headerFilter: "input",
          hozAlign: "right",
        },
        {
          title: "Номер карты",
          field: "number",
          headerFilter: "input",
        },
        {
          title: "Номер счета",
          field: "account_number",
          headerFilter: "input",
        },
        {
          title: "Баланс",
          field: "balance",
          headerFilter: "number",
        },
        {
          title: "ФИО",
          field: "name",
          headerFilter: "number",
        },
        {
          title: "Телефон",
          field: "phone_number",
          headerFilter: "input",
        },
        {
          title: "Трейдер",
          field: "trader_id",
          headerFilter: "input",
        },
        {
          title: "Архив",
          field: "archive",
          headerFilter: "input",
        },
      ],
      rowContextMenu: this.rowContextMenu,
      layout: "fitData",
      height: "100%",
    });

    document.getElementById("main-table")?.appendChild(div);
  }

  async createCard() {
    const card = this.cardForm.value;

    const success = await this.cardsApiService.create(card);

    if (success) {
      this.table.addRow(card);
      this.cardForm.reset();
    }
  }
}
