import { Component, DoCheck, Input } from "@angular/core";

import { TabulatorFull as Tabulator } from "tabulator-tables";
import { FormGroup, FormControl } from "@angular/forms";
import {
  date,
  convertDateTimeZone,
  shortString,
  currentDate,
} from "@environments/helper";

import { environment } from "@environments/environment";
import {
  TradersApiService,
  OtherApiService,
  CardsApiService,
} from "@services/api.service";
import { interval } from "rxjs";

@Component({
  selector: "app-withdraw",
  templateUrl: "./withdraw.component.html",
  styleUrls: ["../body.css"],
})
export class Withdraw {
  constructor(
    private tradersApiService: TradersApiService,
    private otherApiService: OtherApiService,
    private cardsApiService: CardsApiService
  ) {}

  public convertDateTimeZone = convertDateTimeZone;
  apiUrl = environment.apiUrl;
  shortString = shortString;
  JSON = JSON;
  date = date;
  loadingRef = true;
  loadingWithdraw = true;
  loadingCommissions = true;
  loadingSend = false;
  sendDone = false;
  commissions: any = [];
  withdrawals: any = [];
  selectedMethodId: any = 0;
  filterTraderId: any = 0;
  typeWithdraw = null;
  status: any = null;
  selectedMethodIdFilter: any = null;
  searchId: any = null;
  date_from = currentDate(7);
  date_to = currentDate();
  rev = 0;
  showData = false;
  editMethod = false;

  page = 1;
  lastPage = 0;

  @Input() data = {
    amount: 0,
    rate: 0,
    percent: 0,
  };

  ngOnInit() {
    this.getComissions();
    this.getWithdraw();
    this.getWithdrawRev();

    const period = interval(60000);
    period.subscribe((val) => {
      this.loadWithdraw();
    });
  }

  async setStatus(status: any = null) {
    this.status = status;
    this.getWithdraw(true);
  }

  async setPage(page: number) {
    this.page = page;
    await this.getWithdraw();
  }


  getVisiblePages() {
    const visiblePages = [];

    const startPage = Math.max(1, this.page - 2);
    const endPage = Math.min(this.lastPage, this.page + 2);

    for (let i = startPage; i <= endPage; i++) {
      visiblePages.push(i);
    }

    return visiblePages;
  }

  async getComissions() {
    this.loadingCommissions = true;
    const data = await this.otherApiService.getAdmin(
      "get-withdraw-commissions"
    );
    console.log(data);
    this.loadingCommissions = false;
    if ((data as any).success) {
      this.commissions = (data as any).result.commissions.data;
    }
  }

  async getWithdraw(pageReset?: boolean) {
    if (pageReset) this.page = 1;
    this.loadingWithdraw = true;
    await this.loadWithdraw();
    this.loadingWithdraw = false;
  }

  async getWithdrawRev() {
    this.rev = 0;
    this.loadingRef = true;
    const data = await this.otherApiService.getAdmin("get-withdraw-rev", {
      date_from: this.date_from,
      date_to: this.date_to,
    });
    console.log(data);
    this.loadingRef = false;
    if ((data as any).success) {
      this.rev = (data as any).result.rev;
    }
  }

  async loadWithdraw() {
    const data = await this.cardsApiService.getWithdraw({
      page: this.page,
      status: this.status,
      method: this.selectedMethodIdFilter
        ? this.commissions[this.selectedMethodIdFilter]?.key
        : null,
      id: this.searchId == "" ? null : this.searchId,
    });
    console.log(data);
    if ((data as any).success) {
      this.withdrawals = (data as any).data;
      this.lastPage = Number((data as any).last_page);
    }
  }

  async resendCallBack(i: any) {
    this.withdrawals[i]["loading_callback"] = true;
    await this.otherApiService.postApi("resend-callback-withdraw", {
      id: this.withdrawals[i].id,
    });
    this.withdrawals[i]["loading_callback"] = false;
  }

  async cancel(i: any) {
    this.withdrawals[i]["loading_cancel"] = true;
    const result = await this.otherApiService.post("withdraw-cancel", {
      id: this.withdrawals[i].id,
    });
    this.withdrawals[i]["loading_cancel"] = false;
    if ((result as any).success) {
      this.withdrawals[i]["status"] = "canceled";
    }
  }

  async saveComissions() {
    const params = {
      key: this.commissions[this.selectedMethodId].key,
      value: this.commissions[this.selectedMethodId].value,
    };

    this.loadingSend = true;
    await this.otherApiService.post("set-withdraw-commissions", params);
    this.loadingSend = false;
  }
}
