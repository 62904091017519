<div style="height: 100vh; width: 100vh">
  <table class="table" style="margin-top: 20px">
    <thead>
      <tr>
        <td>ID</td>
        <td>Datetime</td>
        <td>Type</td>
        <td>From</td>
        <td>To</td>
        <td>Amount</td>
        <td>Txid</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of history; index as i">
        <td>{{ item.id }}</td>
        <td>{{ date(item.created_at) }}</td>
        <td>{{ item.type }}</td>
        <td>
          <a
            href="https://tronscan.org/#/address/{{ item.address_from }}"
            target="_blank"
            >{{ txHash(item.address_from) }}</a
          >
        </td>
        <td>
          <a
            href="https://tronscan.org/#/address/{{ item.address_to }}"
            target="_blank"
            >{{ txHash(item.address_to) }}</a
          >
        </td>
        <td>{{ item.amount }}</td>
        <td>
          <a
            *ngIf="item.txid"
            href="https://tronscan.org/#/transaction/{{ item.txid }}"
            target="_blank"
            >{{ txHash(item.txid) }}</a
          >
        </td>
      </tr>
    </tbody>
  </table>
</div>
