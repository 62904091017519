<main>
	<input
	[formControl]="realAmountInput"
	class='input'
	#amount placeholder="Изменённая сумма">

	<div class="delimeter"></div>

	<div class="modal" *ngIf="showModal">
		<div class="modal_block">
			<h3 style="text-align: center; color: #000;">Вы подтверждаете заявку.</h3>
			<h3 style="text-align: center; color: #000;" >Вы уверены?</h3>
			<div style="text-align: center;" *ngIf="!loading">
				<button class="btn btn-success mr-2 btn-sm" (click)="payment()">
					Да
				  </button>
				  <button class="btn btn-error mr-2 btn-sm" (click)="cancel()">
					Нет
				  </button>
			</div>
			<div *ngIf="loading" style="text-align: center; color: #000;">Loading...</div>
		</div>
	</div>

	<div style="height: 30rem; min-width: 30rem;" id="main-table"></div>
	
</main>