import { Component, DoCheck, Input } from "@angular/core";

import { TabulatorFull as Tabulator } from "tabulator-tables";
import { FormGroup, FormControl } from "@angular/forms";
import { date } from "@environments/helper";

import { environment } from "@environments/environment";
import { TradersApiService, OtherApiService } from "@services/api.service";

@Component({
  selector: "app-insurance-deposit",
  templateUrl: "./insurance-deposit.component.html",
  styleUrls: ["./insurance-deposit.component.css"],
})
export class InsuranceDepositComponent implements DoCheck {
  constructor(
    private tradersApiService: TradersApiService,
    private otherApiService: OtherApiService
  ) {}

  date = date;
  loadingDeposits = true;
  loadingRate = true;
  loadingTraders = true;
  loadingSend = false;
  sendDone = false;
  table: any;
  traders: any = [];
  deposits: any = [];
  amountDeposit = 0;
  selectedTraderId: any = 0;
  filterTraderId: any = 0;
  amountUndeposit: any = 0;
  type: any = "deposit";

  @Input() data = {
    amount: 0,
    rate: 0,
    percent: 0,
  };

  ngOnInit() {
    this.getTraders();
    this.getRate();
    this.getDeposits();
  }

  ngDoCheck() {
    this.setAmountResult();
  }

  setAmountResult() {
    this.amountDeposit =
      this.data.amount * this.data.rate +
      this.data.amount * this.data.rate * (this.data.percent / 100);
  }

  seletedTrader() {
    this.data.percent = this.traders[this.selectedTraderId].percent_deposit;
  }

  async getTraders() {
    this.loadingTraders = true;
    const data = await this.otherApiService.getAdmin("get-traders");
    this.loadingTraders = false;
    if ((data as any).success) {
      this.traders = (data as any).result;
      this.data.percent = this.traders[this.selectedTraderId].percent_deposit;
    }
  }

  async getDeposits(trader_id: number = 0) {
    console.log(this.filterTraderId);

    this.loadingDeposits = true;
    const data = await this.otherApiService.getAdmin(
      `get-deposits&trader_id=${this.filterTraderId}`
    );
    this.loadingDeposits = false;
    console.log(data);
    if ((data as any).success) {
      this.deposits = (data as any).result;
    }
  }

  async getRate() {
    this.loadingRate = true;
    const data = await this.otherApiService.get("get-rate");
    this.loadingRate = false;
    if ((data as any).success) {
      this.data.rate = (data as any).result.price;
    }
  }

  async sendUndeposit() {
    const params = {
      amount: this.amountUndeposit,
      trader_id: this.traders[this.selectedTraderId].id,
    };
    this.loadingSend = true;
    const data = await this.otherApiService.post("set-undeposit", params);
    this.loadingSend = false;
    console.log(data);
    if ((data as any).success) {
      this.getTraders();
      this.sendDone = true;
      this.getDeposits();
    }
  }

  async sendDeposit() {
    const params = {
      trader_id: this.traders[this.selectedTraderId].id,
      amount_usdt: this.data.amount,
      percent: this.data.percent,
      rate: this.data.rate,
      amount_deposit: this.amountDeposit.toFixed(2),
    };
    this.loadingSend = true;
    const data = await this.otherApiService.post("set-deposit", params);
    this.loadingSend = false;
    console.log(data);
    if ((data as any).success) {
      this.getTraders();
      this.sendDone = true;
      this.getDeposits();
    }
  }
}
