<div class="add-deposit-block">
  <div class="add-deposit">
    <div>
      <button class="btn btn-success mr-2 btn-sm" (click)="type = 'deposit'">
        Deposit
      </button>
      <button class="btn btn-error mr-2 btn-sm" (click)="type = 'undeposit'">
        Списание
      </button>
    </div>
    <div class="item seleted-block">
      <label>Трейдер <span *ngIf="loadingTraders">loading...</span></label>
      <select
        [(ngModel)]="selectedTraderId"
        [ngModelOptions]="{ standalone: true }"
        (change)="seletedTrader()"
      >
        <option *ngFor="let option of traders; let i = index" [value]="i">
          ({{ option.id }}) - {{ option.name }} ({{ option.insurance_deposit }})
        </option>
      </select>
    </div>

    <div class="item" *ngIf="type == 'undeposit'">
      <label>Сумма списания RUB</label>
      <input
        [(ngModel)]="amountUndeposit"
        type="number"
        placeholder="Сумма снятия"
      />
      <button (click)="sendUndeposit()" *ngIf="!loadingSend && !sendDone">
        Отправить
      </button>
      <button *ngIf="loadingSend">Loading</button>
    </div>

    <div *ngIf="type == 'deposit'">
      <div class="item">
        <label>Сумма USDT</label>
        <input
          [(ngModel)]="data.amount"
          type="number"
          placeholder="Сумма зачисления"
        />
      </div>

      <div class="item">
        <label
          >Текущий курс
          <span (click)="getRate()" *ngIf="!loadingRate">
            <img src="/assets/svg/update.svg" width="10px" fill="#fff"
          /></span>
          <span *ngIf="loadingRate" style="background: red">
            <img src="/assets/svg/update.svg" width="10px" fill="#fff"
          /></span>
        </label>
        <input
          [(ngModel)]="data.rate"
          type="number"
          placeholder="Текущий курс"
        />
      </div>

      <div class="item">
        <label>Процент трейдера</label>
        <input [(ngModel)]="data.percent" type="number" placeholder="Процент" />
      </div>

      <div class="itog">
        <span>{{ amountDeposit.toFixed(2) }}</span>
      </div>

      <button (click)="sendDeposit()" *ngIf="!loadingSend && !sendDone">
        Отправить
      </button>
      <button *ngIf="loadingSend">Loading</button>
    </div>
    <button *ngIf="sendDone" style="background: green; color: #fff">
      Успешно
    </button>
  </div>

  <div class="block-deposits">
    <table *ngIf="!loadingDeposits" class="table">
      <thead>
        <tr>
          <td>#</td>
          <td>
            <div class="item seleted-block">
              <label><span *ngIf="loadingTraders">loading...</span></label>
              <select
                [(ngModel)]="filterTraderId"
                [ngModelOptions]="{ standalone: true }"
                (change)="getDeposits()"
              >
                <option [value]="0">All</option>
                <option
                  *ngFor="let option of traders; let i = index"
                  [value]="option.id"
                >
                ({{ option.id }}) - {{ option.name }}
                </option>
              </select>
            </div>
          </td>
          <td>Admin</td>
          <td>Дата</td>
          <td>USDT</td>
          <td>Курс</td>
          <td>Процент</td>
          <td>Сумма</td>
          <td>Баланс</td>
          <td>Тип</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of deposits">
          <td>{{ item.id_deposit }}</td>
          <td>{{ item.name_trader }}</td>
          <td>{{ item.name_admin }}</td>
          <td>{{ date(item.created_at) }}</td>
          <td>{{ item.amount_usdt }}</td>
          <td>{{ item.rate }}</td>
          <td>{{ item.percent }}%</td>
          <td>{{ item.amount_deposit }}</td>
          <td>{{ item.balance_trader }}</td>
          <td>
            <span *ngIf="item.type_deposit">{{ item.type_deposit }}</span>
            <span *ngIf="!item.type_deposit">deposit_address</span>
            <div style="color: green; margin-top: 5px" *ngIf="!item.name_admin">
              AUTO
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
