<main>
    <form (ngSubmit)="createCard()" [formGroup]="cardForm">
        <div class="input-container">
            <input  required
            formControlName="number"
            placeholder="Номер карты">
        </div> 

        <div class="input-container">
            <input required
            formControlName="balance"
            placeholder="Баланс">
        </div>


        <div class="input-container">
            <input required
            formControlName="name"
            placeholder="ФИО">
        </div>

        <div class="input-container">
            <input required 
            formControlName="phone"
            placeholder="Телефон">
        </div>
        <button>Добавить карту</button>
    </form>
    

    <div style="height: 25rem; min-width: 30rem;" id="main-table"></div>
</main>
