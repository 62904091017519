export const date = (unix: number) => {
  return new Date(unix * 1000).toLocaleString();
};

export const currentDate = (days: number = 0) => {
  const currentDate = new Date();
  if (days > 0) currentDate.setDate(currentDate.getDate() - days);
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");

  return year + "-" + month + "-" + day;
};

export const jsonDecode = (item: any) => {
  return JSON.parse(item);
};

export const getStatus = (statusCode: number | string): string => {
  if (statusCode == -1) return "Отклонен";
  if (statusCode == 0) return "Закрыта";
  if (statusCode == 1) return "В процессе";
  return "unknow";
};

export const getCookie = (name: string) => {
  var matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const txHash = (hash: string) => {
  if (!hash || hash.length == 0) return "tx";
  return `${hash[0]}${hash[1]}${hash[2]}...${hash[hash.length - 3]}${
    hash[hash.length - 2]
  }${hash[hash.length - 1]}`;
};

export const convertDateTimeZone = (dateTimeString: string) => {
  if (!dateTimeString) return "";
  return dateTimeString.split(".")[0];
};

export const shortString = (s: string) => {
  return `${s[0]}${s[1]}${s[2]}...${s[s.length - 5]}${s[s.length - 4]}${
    s[s.length - 3]
  }${s[s.length - 2]}${s[s.length - 1]}`;
};
