<div class="add-deposit-block" style="margin-top: 50px">
  <div class="block-deposits">
    <h3>Создание мерчанта</h3>

    <div class="add-deposit" style="margin-top: 10px">
      <div class="item">
        <label>Name</label>
        <input type="text" [(ngModel)]="name" />
      </div>
      <div class="item">
        <label>Username</label>
        <input type="text" [(ngModel)]="username" />
      </div>
      <div class="item">
        <label>Commission</label>
        <input type="text" [(ngModel)]="commission" />
      </div>
      <div class="item">
        <label>Address</label>
        <input type="text" [(ngModel)]="address" />
      </div>

      <button style="margin-top: 10px" *ngIf="loadingSend">Loading</button>
      <button
        style="margin-top: 10px"
        (click)="create()"
        *ngIf="!loadingSend && !sendStatus"
      >
        Создать мерчанта
      </button>

      <div
        style="color: green; text-align: center; padding: 10px"
        *ngIf="sendStatus"
      >
        Мерчант успешно создан

        <div style="color: orange; margin-top: 20px;">Данные мерчанта</div>
        
        <div style="color: orange; font-size: 18px; margin-top: 10px;">Username: {{usernameReturned}}</div>
        <div style="color: red; font-size: 18px; margin-top: 10px;">Пароль: {{password}}</div>
      </div>

      

    </div>
  </div>
</div>
