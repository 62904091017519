import { Component } from "@angular/core";

import { date, txHash } from "@environments/helper";
import { Router } from "@angular/router";

import { OtherApiService } from "@services/api.service";

@Component({
  selector: "app-insurance-deposit",
  templateUrl: "./deposit-trx.component.html",
  styleUrls: ["../body.css"],
})
export class DepositTrx {
  constructor(
    private router: Router,
    private otherApiService: OtherApiService
  ) {}

  date = date;
  txHash = txHash;
  loadingTraders = true;
  loadingInfo = true;
  loadingSend = false;
  selectedAll = true;
  selectedMerchant = 0;
  traders: any = [];
  tradersLocal: any = [];
  info: any = [];
  amountAll = 0;
  countTraders = 0;
  amount_deposit_trx = 0;
  ngOnInit() {
    this.getInfoDepositTrx();
    this.getTradersLocal();
  }

  updateInfo() {
    this.countTraders = 0;
    this.amountAll = 0;
    this.traders.map((arr: any) => {
      if (arr.checked) {
        this.countTraders++;
        this.amountAll += Number(arr.percent);
        console.log(arr.percent);
      }
    });
  }

  onCheckboxChangeAll() {
    this.traders.map((arr: any) => (arr.checked = this.selectedAll));
    this.updateInfo();
  }

  onPercentChangeAll() {
    this.traders.map((arr: any) => {
      arr.percent = this.info.amount_deposit_trx;
    });
    this.updateInfo();
  }
  onCheckboxChange() {
    this.updateInfo();
  }

  async getTraders() {
    this.loadingTraders = true;
    const data = await this.otherApiService.getAdmin("get-traders-balances");
    console.log(data);
    this.loadingTraders = false;
    if ((data as any).success) {
      this.traders = (data as any).result;
      this.traders.map(
        (arr: any) => (arr.percent = this.info.amount_deposit_trx)
      );
      this.traders.map((arr: any) => (arr.checked = !arr.checked));
      this.updateInfo();
    }
  }
  async getTradersLocal() {
    const data = await this.otherApiService.getAdmin("get-traders");
    console.log(data);
    if ((data as any).success) {
      this.tradersLocal = (data as any).result;
    }
  }

  getTraderUsername(address: any) {
    let trader: any = null;
    this.tradersLocal.filter((item: any) => {
      if (item.address?.toLowerCase() == address?.toLowerCase()) {
        trader = item;
      }
    });

    if (!trader) return null;

    return `(${trader.username})`;
  }

  async getInfoDepositTrx() {
    this.loadingInfo = true;
    const data = await this.otherApiService.getAdmin("get-info-deposit-trx");
    console.log(data);
    this.loadingInfo = false;
    if ((data as any).success) {
      this.info = (data as any).result;
    }
    this.getTraders();
  }
  async send() {
    let traders: any = [];
    this.traders.map((arr: any) => {
      if (arr.checked) {
        traders.push({
          address: arr.address,
          amount_trx: Number(arr.percent),
        });
      }
    });

    console.log(traders);

    this.loadingSend = true;
    const result = await this.otherApiService.postBody("deposit-trx", traders);
    console.log(result);
    if ((result as any).success) {
      this.router.navigate(["/tron-history"]);
    }
    this.loadingSend = false;
  }
}
