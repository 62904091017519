import { Component } from "@angular/core";
import { OtherApiService } from "@services/api.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "merchant-create",
  templateUrl: "./merchant-create.component.html",
  styleUrls: ["../body.css"],
})
export class MerchantCreate {
  constructor(
    private route: ActivatedRoute,
    private otherApiService: OtherApiService
  ) {}

  loading = true;
  loadingSend = false;
  username: any = "";
  name: any = "";
  commission: any = "";
  address: any = "";
  sendStatus: any = false;
  password: any = "";
  usernameReturned: any = "";

  async create() {
    this.sendStatus = false;
    this.loadingSend = true;

    const data = await this.otherApiService.post("create-merchant", {
      name: this.name,
      username: this.username,
      commission: this.commission,
      address: this.address,
    });

    this.loadingSend = false;
    console.log(data);
    if ((data as any).success) {
      this.password = (data as any).result.password;
      this.usernameReturned = (data as any).result.username;
      this.sendStatus = true;
      this.username = "";
      this.name = "";
      this.commission = "";
      this.address = "";
    }
  }
}
