<div style="height: 100vh; width: 100vh">
  <div class="block_send_dist" style="width: 100%; text-align: left">
    <h2>
      <span>Трейдер #{{ traderId }} </span>
      <span *ngIf="!loadingTrader && trader.referrer_id">
        (референт
        <a href="/trader/{{ trader.referrer_id }}">{{
          trader.referrer_username
        }}</a
        >)
      </span>
    </h2>

    <div *ngIf="loadingTrader">Loading trader...</div>

    <div class="add-deposit" style="margin-top: 10px" *ngIf="!loadingTrader">
      <div class="item">
        <label>Username</label>
        <input type="text" [(ngModel)]="trader.username" />
      </div>
      <div class="item">
        <label>Name</label>
        <input type="text" [(ngModel)]="trader.name" />
      </div>
      <div class="item">
        <label>Phone</label>
        <input type="text" [(ngModel)]="trader.phone" />
      </div>
      <div class="item">
        <label>Address (TRON)</label>
        <input type="text" [(ngModel)]="trader.address" />
      </div>
      <div class="item">
        <label>Процент</label>
        <input type="text" [(ngModel)]="trader.percent_deposit" />
      </div>

      <h4 style="margin-top: 10px;">
        Лимиты
        <button class="btn btn-success btn-sm" (click)="showLimit = !showLimit">
          <span *ngIf="showLimit" style="font-size: 12px;">Скрыть</span>
          <span *ngIf="!showLimit" style="font-size: 12px;">Показать</span>
        </button>
        
      </h4>
      <div *ngIf="showLimit">
        <div class="item" *ngFor="let item of limitsKeys; index as i">
          <label>{{ limitsMap[limitsKeys[i]] ?? 'Неизвестное поле'}}</label>
          <input type="text" [(ngModel)]="limits[item]" />
        </div>
      </div>
      

      <button style="margin-top: 10px" *ngIf="loadingEditTrader">
        Loading
      </button>
      <button
        style="margin-top: 10px"
        (click)="editTrader()"
        *ngIf="!loadingEditTrader"
      >
        Сохранить информацию
      </button>
      <div style="color: green; margin: 10px; text-align: center;" *ngIf="saved">Сохранено</div>
    </div>
    <div
      class="add-deposit"
      style="margin-top: 10px"
      *ngIf="!loadingReferrals && referrals.length > 0"
    >
      <h5>
        Рефералы текущего трейдера
        <img
          class="img-add-user"
          src="/assets/svg/add-user.svg"
          width="20"
          (click)="isShowReferrals = !isShowReferrals"
          *ngIf="feeReferrals.length > 0"
        />
      </h5>
      <label *ngFor="let item of referrals; index as i" class="referral_item">
        <a href="/trader/{{ item.id }}">
          {{ item.username }} ({{ item.percent_deposit }}%)
        </a>
        <img
          *ngIf="!loadingDeleteReferral"
          src="/assets/svg/delete.svg"
          width="20"
          (click)="deleteReferral(item.id)"
        />
      </label>
    </div>
    <button
      style="margin-top: 10px"
      (click)="isShowReferrals = !isShowReferrals"
      *ngIf="!loadingReferrals && !isShowReferrals && referrals.length == 0"
    >
      Добавить реферала.
    </button>
    <div
      class="add-deposit"
      style="margin-top: 10px"
      *ngIf="!loadingReferrals && feeReferrals.length > 0 && isShowReferrals"
    >
      <h5>Свободные трейдеры (без референтов)</h5>
      <label
        *ngFor="let item of feeReferrals; index as i"
        class="referral_item"
      >
        <a href="/trader/{{ item.id }}">
          {{ item.username }} ({{ item.percent_deposit }}%)
        </a>
        <img
          *ngIf="!loadingDeleteReferral"
          src="/assets/svg/add-user.svg"
          width="20"
          (click)="addReferral(item.id)"
        />
      </label>
    </div>

    <div>
      <button
        style="margin-top: 10px; background-color: red; color: #fff"
        (click)="showBanTrader = !showBanTrader"
        *ngIf="!showBanTrader && !loadingTrader && trader.ban_time == 0"
      >
        Заблокировать трейдера
      </button>

      <div class="add-deposit" style="margin-top: 10px" *ngIf="showBanTrader">
        <div class="item">
          <label>Часы</label>
          <input type="text" [(ngModel)]="banHours" />
        </div>
        <div class="item">
          <label>Минуты</label>
          <input type="text" [(ngModel)]="banMinutes" />
        </div>
        <button
          style="margin-top: 10px; background-color: red; color: #fff"
          (click)="addTraderBan()"
        >
          <span *ngIf="!loadingBanTrader">Заблокировать трейдера</span>
          <span *ngIf="loadingBanTrader">Loading...</span>
        </button>
      </div>
    </div>

    <div *ngIf="!loadingTrader && trader.ban_time > 0">
      <h4 style="text-align: center; color: red; margin-top: 20px">
        Трейдер заблокирован до {{ date(trader.ban_time) }}
      </h4>
      <button
        style="margin-top: 10px; background-color: red; color: #fff"
        (click)="traderBanReset()"
      >
        <span *ngIf="!loadingBanTrader">Разблокировать</span>
        <span *ngIf="loadingBanTrader">Loading...</span>
      </button>
    </div>
  </div>
</div>
