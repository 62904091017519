import { Component } from "@angular/core";

import { interval } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { environment } from "@environments/environment";
import { OtherApiService } from "@services/api.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class navbarComponent {
  rate = 0;
  loadingRate = true;
  countNotifs = 0;

  constructor(private otherApiService: OtherApiService) {}

  async ngOnInit() {
    this.getCountNotifs();
    this.loadingRate = true;
    await this.getRate();
    this.loadingRate = false;
    const period = interval(10000);
    period.subscribe((val) => {
      this.getCountNotifs();
      this.getRate();
    });

    
  }

  async getCountNotifs() {
    const data = await this.otherApiService.getAdmin("get-count-new-notifs");
    if ((data as any).success) {
      this.countNotifs = (data as any).result.count_notification;
    }
  }

  async getRate() {
    const data = await this.otherApiService.getApi("get-rate");
    console.log(data);
    if ((data as any).success) {
      this.rate = Number((data as any).result.price);
    }
  }
}
