import { Component } from "@angular/core";

import { TradersApiService, OtherApiService } from "@services/api.service";

@Component({
  selector: "app-insurance-deposit",
  templateUrl: "./rate.component.html",
  styleUrls: ["../body.css"],
})
export class Rate {
  constructor(
    private tradersApiService: TradersApiService,
    private otherApiService: OtherApiService
  ) {}

  settings: any = {};
  loadingSettings = true;
  loadingSend = false;
  activeSourceRate = "";
  duration: any = 0;

  ngOnInit() {
    this.loadSettings();
  }

  seletedSourceRate() {}

  async save() {
    console.log(this.settings);
    this.loadingSend = true;
    await this.otherApiService.post("set-rate", this.settings);
    this.loadingSend = false;
    this.loadSettings();
  }
  async loadSettings() {
    this.loadingSettings = true;
    const data = await this.otherApiService.getAdmin("get-settings");
    this.loadingSettings = false;
    if ((data as any).success) {
      this.settings = (data as any).result;
      this.activeSourceRate = this.settings.source_rate;
      let duration =
        (this.settings.duration_rate - Date.now() / 1000) / 60 / 60;

      this.settings.duration_rate = duration < 0 ? 0 : duration.toFixed(1);
    }
  }
}
