<div class="add-deposit-block">
  <div class="add-deposit">
    <div class="item seleted-block">
      <label
        >Источник курса <span *ngIf="loadingSettings">loading...</span></label
      >
      <select [(ngModel)]="settings.source_rate">
        <option value="auto">
          ByBit <span *ngIf="activeSourceRate == 'auto'">(активен)</span>
        </option>
        <option value="manual">
          Вручную <span *ngIf="activeSourceRate == 'manual'">(активен)</span>
        </option>
      </select>
    </div>

    <div *ngIf="settings.source_rate == 'manual'">
      <div class="item">
        <label>Курс </label>
        <input [(ngModel)]="settings.rate" type="number" />
      </div>

      <div class="item">
        <label
          >Длительность курса в часах
          <span *ngIf="settings.duration_rate > 0"
            >(осталось {{ settings.duration_rate }} ч.)</span
          >
          <span
            *ngIf="settings.duration_rate <= 0 && activeSourceRate == 'manual'"
            >(в процессе переключения на курс ByBit)</span
          ></label
        >
        <input [(ngModel)]="settings.duration_rate" type="number" />
      </div>
    </div>

    <button (click)="save()" *ngIf="!loadingSend">Сохранить</button>
    <button *ngIf="loadingSend">Loading</button>
  </div>
</div>
