import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

import { TabulatorFull as Tabulator } from "tabulator-tables";
import { FormGroup, FormControl } from "@angular/forms";

import { OtherApiService, TradersApiService } from "@services/api.service";

import { environment } from "@environments/environment";

import { getCookie } from "@environments/helper";

@Component({
  selector: "app-traders-table",
  templateUrl: "./traders-table.component.html",
  styleUrls: ["./traders-table.component.css"],
})
export class TradersTableComponent {
  ngOnInit() {
    this.drawTable();
  }

  traderForm: any = new FormGroup({
    username: new FormControl(),
    password: new FormControl(),
    name: new FormControl(),
    phone: new FormControl(),
  });

  table: any;
  loading: any = false;

  constructor(
    private tradersApiService: TradersApiService,
    private otherApiService: OtherApiService
  ) {}

  rowContextMenu = [
    {
      label: "Удалить трейдера",
      action: async (e: any, row: any) => {
        const id = row.getCell("id").getValue();

        const success = await this.tradersApiService.delete(id);

        if (success) {
          row.delete();
        }
      },
    },
  ];

  private drawTable(): void {
    var div = document.createElement("div");

    this.table = new Tabulator(div, {
      persistence: true,
      filterMode: "remote",
      sortMode: "remote",
      pagination: true,
      paginationMode: "remote",
      paginationSize: 300,
      paginationSizeSelector: [5, 10, 15, 20, 50, 100, 5000, 100000, true],
      ajaxURL: environment.apiUrl + "/trader/get",
      ajaxConfig: {
        headers: {
          Authorization: JSON.parse(getCookie("json_token") || "{}")["token"],
        },
      },
      columns: [
        {
          title: "ID",
          field: "id",
          headerFilter: "input",
        },
        {
          title: "Приоритет",
          field: "priority",
          headerFilter: "input",

          formatter: (cell) => {
            const data: any = cell.getData();

            var priority = data.priority;

            if(data.priority == 2) {
                var color = "#29AD30";
                var weight = "400";
            }
            else if(data.priority == 3) {
                var color = "#FCC214";
                var weight = "500";
            }
            else if(data.priority == 4) {
                var color = "#F90A02";
                var weight = "700";
            }
            else if(data.priority == 5) {
                var weight = "900";
                var color = "#A713F7";
            }            
            else {
                var weight = "400";
                var color = "#FFFFFF";
            }
            return `<span style="color: ${color}; font-weight: ${weight}">${priority}</span>`;
          },
          cellClick: async (e, cell: any) => {
            const data: any = cell.getData();

            this.loading = true;

            if(data.priority == 5) var newPriority = 1
            else var newPriority = (Number(data.priority))+1

            const result = await this.otherApiService.getAdmin(
              "set-trader-priority",
              { trader_id: data.id, value: newPriority }
            );
            this.loading = false;
            if ((result as any).success) {
                cell.setValue(newPriority);
            }

            console.log("Priority is: " + data.priority + " OR " + newPriority)
          },
        },
        {
            title: "Вывод",
            field: "withdraw_success",
            headerFilter: "input",
  
            formatter: (cell) => {
              const data: any = cell.getData();
              const checked = data.withdraw_success ? "checked" : "";
              return `<input type="checkbox" style="width: 100%; height: 100%;" ${checked}>`;
            },
            cellClick: async (e, cell: any) => {
              const data: any = cell.getData();
  
              this.loading = true;
              const result = await this.otherApiService.getAdmin(
                "edit-withdraw-success-tarder",
                { trader_id: data.id, value: !data.withdraw_success }
              );
              this.loading = false;
              if ((result as any).success) {
                data.withdraw_success = !data.withdraw_success;
              }
            },
          },
          {
          title: "Имя пользователя.",
          field: "username",
          headerFilter: "input",
          formatter: (cell) => {
            const data: any = cell.getData();
            return `<a href="/trader/${data.id}">${data.username}</a>`;
          },
        },
        {
          title: "ФИО",
          field: "name",
          headerFilter: "input",
        },
        {
          title: "Баланс",
          field: "insurance_deposit",
          headerFilter: "input",
          bottomCalc: "sum"
        },
        {
          title: "Замороженный баланс",
          field: "frozen_balance",
          headerFilter: "input",
          bottomCalc: "sum"
        },
        {
          title: "Номер телефона",
          field: "phone",
          headerFilter: "input",
        },
        {
          title: "Адрес",
          field: "address",
          headerFilter: "input",
        },
      ],
      rowContextMenu: this.rowContextMenu,
      layout: "fitData",
      height: "100%",
    });

    document.getElementById("main-table")?.appendChild(div);
  }

  async createTrader() {
    const trader = this.traderForm.value;

    const success = await this.tradersApiService.create(trader);

    if (success) {
      this.table.addRow(trader);
      this.traderForm.reset();
    }
  }
}
