import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";

import { environment } from "@environments/environment";
import { CookieService } from "ngx-cookie-service";
import { firstValueFrom } from "rxjs";
import { getCookie } from "@environments/helper";

const headers = {
  Authorization: JSON.parse(getCookie("json_token") || "{}")["token"],
};

export class ApiService {
  isSuccess(data: any): boolean {
    const success = data.success;

    if (!success) {
      this.error();
    }

    return success;
  }

  error() {
    alert("Something's wrong.");
  }
}

@Injectable({
  providedIn: "root",
})
export class PaymentsApiService extends ApiService {
  private url: string = environment.apiUrl;

  constructor(private http: HttpClient) {
    super();
  }

  async confirm(id: string, real_amount: string | number) {
    var url = this.url + "/payments/confirm?paymentid=" + id;

    if (real_amount) {
      url = url + "&real_amount=" + real_amount;
    }

    const req = this.http.get(url, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }

  async cancel(id: string) {
    const url = this.url + "/payments/cancel";

    const body = new FormData();
    body.append("paymentid", id);

    const req = this.http.post(url, body, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }
}

@Injectable({
  providedIn: "root",
})
export class CardsApiService extends ApiService {
  private url: string = environment.apiUrl;

  constructor(private http: HttpClient) {
    super();
  }

  async activate(number: string) {
    const url = this.url + "/cards/activate";
    const body = new FormData();
    body.append("number", number);

    const req = this.http.post(url, body, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }

  async deactivate(number: string) {
    const url = this.url + "/cards/deactivate";
    const body = new FormData();
    body.append("number", number);

    const req = this.http.post(url, body, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }

  async create(card: any) {
    const url = this.url + "/cards/create";
    const body = new FormData();

    for (let key in card) {
      body.append(key, card[key]);
    }

    body.append("trader_id", "24");

    const req = this.http.post(url, body, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }

  async delete(number: string) {
    const url = this.url + "/cards/delete";

    const body = new FormData();
    body.append("number", number);

    const req = this.http.post(url, body, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }

  async getWithdraw(params: any = {}) {
    console.log(params);
    const urlParams = Object.keys(params)
      .map((key) => {
        if (!key || !params[key]) return "";
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
      })
      ?.join("&");

    const url = this.url + "/withdrawals/get?" + urlParams;
    const body = new FormData();
    const req = this.http.post(url, body, { responseType: "json", headers });
    return await req.toPromise();
  }
}

@Injectable({
  providedIn: "root",
})
export class TradersApiService extends ApiService {
  private url: string = environment.apiUrl;

  constructor(private http: HttpClient) {
    super();
  }

  async create(card: any) {
    const url = this.url + "/trader/create";
    const body = new FormData();

    for (let key in card) {
      body.append(key, card[key]);
    }

    const req = this.http.post(url, body, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }

  async delete(traderId: string) {
    const url = this.url + "/trader/delete";

    const body = new FormData();
    body.append("trader_id", traderId);

    const req = this.http.post(url, body, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }

  async addDeposit(formData: any) {
    const url = this.url + "/trader/deposit/add";
    const body = new FormData();

    body.append("trader_id", formData["username"]);
    body.append("amount", formData["amount"]);

    const req = this.http.post(url, body, { responseType: "json", headers });

    const data = await req.toPromise();

    const success = await this.isSuccess(data as any);

    return success;
  }
}
@Injectable({
  providedIn: "root",
})
export class OtherApiService extends ApiService {
  private url: string = environment.apiUrl;

  constructor(private http: HttpClient, private cookieService: CookieService) {
    super();
  }

  /*private headers = {
    'Content-Type': 'application/json',
    'Authorization': JSON.parse(this.cookieService.get('json_token') || '{}')['token'],
  }*/

  async getAdmin(method: string, params: any = {}) {
    const url = this.url + `/api/admin?method=${method}`;
    return await firstValueFrom(this.http.get(url, { headers, params }));
  }

  async get(method: string) {
    const url = this.url + `/api/?method=${method}`;
    return await firstValueFrom(this.http.get(url, { headers }));
  }

  async post(method: string, _params: any) {
    const params = { method, ..._params };
    return await firstValueFrom(
      this.http.post(this.url + "/api/admin", null, { params: params, headers })
    );
  }

  async postBody(method: string, _params: any) {
    const params = { method };
    return await firstValueFrom(
      this.http.post(this.url + "/api/admin", _params, {
        params: params,
        headers,
      })
    );
  }

  async getApi(method: string, _params = {}) {
    const params = { method, ..._params };
    return await firstValueFrom(
      this.http.get(this.url + "/api/", { params: params, headers })
    );
  }

  async postApi(method: string, _params = {}) {
    const params = { method, ..._params };
    return await firstValueFrom(
      this.http.post(this.url + "/api/", _params, {
        params: params,
        headers,
      })
    );
  }
}
