<main>
    <input
    [formControl]="realAmountInput"
    class='input'
    #amount placeholder="Изменённая сумма">

    <div class="delimeter"></div>

    <div style="height: 30rem; min-width: 30rem;" id="main-table"></div>
    
</main>