<div style="height: 100vh; width: 100vh">
  <div class="block_send_dist">
    <h2>ОТЧИСЛЕНИЕ БК</h2>
    <div class="amount_block">{{ amountAll.toFixed(4) }} USDT</div>
    <div class="item seleted-block" style="margin-bottom: 10px">
      <div><span *ngIf="loadingMerchants">loading merchants...</span></div>
      <div style="text-align: left; font-size: 12px" *ngIf="!loadingMerchants">
        Выберите, какому мерчанту совершать перевод
      </div>
      <select
        *ngIf="!loadingMerchants"
        [(ngModel)]="selectedMerchant"
        [ngModelOptions]="{ standalone: true }"
      >
        <option *ngFor="let option of merchants; let i = index" [value]="i">
          {{ txHash(option.address) }} ({{ option.name }})
        </option>
      </select>
    </div>
    <div><span *ngIf="loadingTraders">Loading traders...</span></div>
    <div><span *ngIf="loadingSend">Отправка...</span></div>
    <div>
      <button (click)="send()" *ngIf="!loadingSend && amountAll > 0">
        Отправить
      </button>
    </div>
  </div>
  <table class="table">
    <thead>
      <tr>
        <td>
          <input
            type="checkbox"
            [(ngModel)]="selectedAll"
            (change)="onCheckboxChangeAll()"
          />
        </td>
        <td>Трейдер</td>
        <td>Баланс TRX</td>
        <td>Баланс USDT</td>
        <td>
          <input
            style="width: 50px"
            type="text"
            [(ngModel)]="percent"
            (input)="onPercentChangeAll()"
          />%
        </td>
        <td>Итог USDT</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of traders; index as i">
        <td>
          <input
            type="checkbox"
            [(ngModel)]="item.checked"
            (change)="onCheckboxChange()"
          />
        </td>
        <td>
          <a
            href="https://tronscan.org/#/address/{{ item.address }}"
            target="_blank"
            >{{ txHash(item.address) }}</a
          >
          <span style="color: rgb(47, 189, 47); padding-left: 10px">{{
            getTraderUsername(item.address)
          }}</span>
        </td>
        <td>{{ item.amount_trx }}</td>
        <td>{{ item.amount_usdt }}</td>
        <td>
          <input
            style="width: 50px"
            type="text"
            [(ngModel)]="item.percent"
            (input)="onPercentChange(i)"
          />%
        </td>
        <td style="color: green">{{ item.amount_usdt_send }}</td>
      </tr>
    </tbody>
  </table>
</div>
