<div style="height: 100vh; width: 100vh">
  <div class="block_send_dist">
    <h2>ПОПОЛНЕНИЕ TRX</h2>
    <div>
      <div><span *ngIf="loadingInfo">Loading info...</span></div>
      <div class="amount_block" *ngIf="!loadingInfo">
        <a
          href="https://tronscan.org/#/address/{{ info.address }}"
          target="_blank"
          >{{ info.address }}</a
        >
        <br />
        Баланс фонда {{ info.balance_trx.toFixed(4) }} TRX
      </div>
    </div>
    <div class="amount_block">
      Общая сумма пополнений<br />
      {{ amountAll.toFixed(4) }} TRX
    </div>
    <div><span *ngIf="loadingTraders">Loading traders...</span></div>
    <div *ngIf="loadingSend">Loading...</div>
    <div *ngIf="!loadingSend">
      <button
        (click)="send()"
        *ngIf="amountAll > 0 && info.balance_trx > amountAll"
      >
        Пополнить
      </button>
    </div>
    <div *ngIf="info.balance_trx < amountAll" style="color: red">
      На балансе недостаточно TRX
    </div>
  </div>
  <table class="table">
    <thead>
      <tr>
        <td>
          <input
            type="checkbox"
            [(ngModel)]="selectedAll"
            (change)="onCheckboxChangeAll()"
          />
        </td>
        <td>Трейдер</td>
        <td>Баланс USDT</td>
        <td>Баланс TRX</td>
        <td>
          <input
            style="width: 100px"
            type="text"
            [(ngModel)]="info.amount_deposit_trx"
            (input)="onPercentChangeAll()"
          />TRX
        </td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of traders; index as i">
        <td>
          <input
            type="checkbox"
            [(ngModel)]="item.checked"
            (change)="onCheckboxChange()"
          />
        </td>
        <td>
          <a
            href="https://tronscan.org/#/address/{{ item.address }}"
            target="_blank"
            >{{ txHash(item.address) }}</a
          >
          <span style="color: rgb(47, 189, 47); padding-left: 10px">{{
            getTraderUsername(item.address)
          }}</span>
        </td>
        <td>{{ item.amount_usdt }}</td>
        <td>
          {{ item.amount_trx }}
          <div
            style="color: orange; font-size: 12px"
            *ngIf="item.amount_trx < info.min_balance_trx"
          >
            (требует пополнения)
          </div>
        </td>

        <td>
          <input
            style="width: 100px"
            type="text"
            [(ngModel)]="item.percent"
            (input)="updateInfo()"
          />
        </td>
      </tr>
    </tbody>
  </table>
</div>
