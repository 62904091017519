import { Component, DoCheck, Input } from "@angular/core";

import { TabulatorFull as Tabulator } from "tabulator-tables";
import { FormGroup, FormControl } from "@angular/forms";
import { date } from "@environments/helper";

import { environment } from "@environments/environment";
import { TradersApiService, OtherApiService } from "@services/api.service";

@Component({
  selector: "merchants",
  templateUrl: "./merchants.component.html",
  styleUrls: ["../body.css"],
})
export class Merchants {
  constructor(
    private tradersApiService: TradersApiService,
    private otherApiService: OtherApiService
  ) {}

  loading = true;
  data: any = [];

  ngOnInit() {
    this.getMerchants();
  }

  async getMerchants() {
    this.loading = true;
    const data = await this.otherApiService.getAdmin("get-merchants");
    this.loading = false;
    if ((data as any).success) {
      this.data = (data as any).result;
    }
  }
}
