<div style="height: 100vh; width: 100vh">
  <div
    class="add-deposit"
    style="margin: auto; margin-bottom: 20; margin-top: 40px"
  >
    <h2 style="text-align: center">ОТЧИСЛЕНИЕ ПРИБЫЛИ</h2>
    <div class="item">
      <label>Общая сумма заявок, руб</label>
      <input [(ngModel)]="amountAll" type="number" disabled />
    </div>
    <div class="item">
      <label
        >Текущий курс
        <span (click)="getRate()" *ngIf="!loadingRate">
          <img src="/assets/svg/update.svg" width="10px" fill="#fff"
        /></span>
        <span *ngIf="loadingRate" style="background: red">
          <img src="/assets/svg/update.svg" width="10px" fill="#fff"
        /></span>
      </label>
      <input
        [(ngModel)]="rate"
        (input)="updateInfo()"
        type="number"
        placeholder="Текущий курс"
      />
    </div>
    <div class="item">
      <label>Процент</label>
      <input
        [(ngModel)]="percent"
        (input)="updateInfo()"
        type="number"
        placeholder="Процент"
      />
    </div>
    <div class="item">
      <label>Прибыль USDT</label>
      <input [(ngModel)]="amountResult" type="number" />
    </div>
    <div class="item seleted-block" style="margin-bottom: 10px">
      <label *ngIf="!loadingTraders"
        >Выберите трейдера с которого спишется прибыль</label
      >
      <div
        style="color: red; font-size: 12px"
        *ngIf="
          !loadingTraders && amountResult > traders[selectedTrader].amount_usdt
        "
      >
        У выбранного трейдера недостаточно
        {{ (amountResult - traders[selectedTrader].amount_usdt).toFixed(4) }}
        USDT
      </div>
      <div><span *ngIf="loadingTraders">loading traders...</span></div>
      <select
        [(ngModel)]="selectedTrader"
        [ngModelOptions]="{ standalone: true }"
      >
        <option *ngFor="let option of traders; let i = index" [value]="i">
          {{ option.name }} ({{ option.amount_usdt }})
          {{ txHash(option.address) }}
        </option>
      </select>
    </div>
    <span *ngIf="loadingSend" style="text-align: center">Loading...</span>
    <div *ngIf="!loadingSend">
      <button
        *ngIf="
          !loadingTraders && traders[selectedTrader].amount_usdt > amountResult
        "
        (click)="send()"
      >
        Списать прибыль с трейдера {{ traders[selectedTrader].name }}
      </button>
    </div>
  </div>
  <div class="data_block">
    <div class="item">
      <p>Период от</p>
      <input type="date" [(ngModel)]="date_from" />
    </div>
    <div class="item">
      <p>Период до</p>
      <input type="date" [(ngModel)]="date_to" />
    </div>
  </div>
  <div style="max-width: 500px; margin: auto">
    <button (click)="getPayments()">
      <span *ngIf="loadingPayments">Loading...</span>
      <span *ngIf="!loadingPayments">Найти платежи</span>
    </button>
  </div>
  <table class="table">
    <thead>
      <tr>
        <td>
          <input
            type="checkbox"
            [(ngModel)]="selectedAll"
            (change)="onCheckboxChangeAll()"
          />
        </td>
        <td>Payment ID</td>
        <td>Дата</td>
        <td>Сумма</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of payments.payments; index as i">
        <td>
          {{ i + 1 }}
          <input
            type="checkbox"
            [(ngModel)]="item.checked"
            (change)="updateInfo()"
          />
        </td>
        <td>{{ item.id }}</td>
        <td>{{ item.creation_time }}</td>
        <td>{{ item.real_amount }}</td>
      </tr>
    </tbody>
  </table>
</div>
