<body>
  <main>
    <div class="main-inner">
      <div class="service" routerLink="/cards">
        <img class="service-svg" src="/assets/svg/cards-page.svg" />
      </div>
      <div class="service" routerLink="/payments">
        <img class="service-svg" src="/assets/svg/payments-page.svg" />
      </div>

      <div class="service" routerLink="/withdraw">
        <img class="service-svg" src="/assets/svg/withdraw.svg" />
      </div>

      <div class="service" routerLink="/traders">
        <img class="service-svg" src="/assets/svg/traders-page.svg" />
      </div>
      <div class="service" routerLink="/insurance-deposit">
        <img class="service-svg" src="/assets/svg/deposit-page.svg" />
      </div>
      <div class="service" routerLink="/deposit-merchant">
        <img class="service-svg" src="/assets/svg/deposit-merchant.svg" />
      </div>
      <div class="service" routerLink="/merchants">
        <img class="service-svg" src="/assets/svg/ball.svg" />
      </div>
      <div class="service" routerLink="/distribution-bk">
        <img class="service-svg" src="/assets/svg/betting.svg" />
      </div>
      <div class="service" routerLink="/distribution-profit">
        <img class="service-svg" src="/assets/svg/profit.svg" />
      </div>
      <div class="service" routerLink="/deposit-trx">
        <img class="service-svg" src="/assets/svg/tron-trx-red.svg" />
      </div>
      <div class="service" routerLink="/tron-history">
        <img class="service-svg" src="/assets/svg/book.svg" />
      </div>
      <div class="service" routerLink="/notifications" title="Уведомления">
        <img src="/assets/svg/notification-new.svg" class="service-svg" />
        <span style="min-width: 20px;" *ngIf="countNotifs > 0">{{ countNotifs }}</span>
      </div>

      <div class="service service-left" routerLink="/rate">
        <img class="service-svg" src="/assets/svg/usdt-logo.svg" />
      </div>
      <div class="service service-right" routerLink="/rate">
        <strong *ngIf="!loadingRate">{{ rate.toFixed(2) }}₽</strong>
        <strong *ngIf="loadingRate">Loading...</strong>
      </div>
    </div>
  </main>
</body>
