import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TabulatorAdditionalService {
  public minMaxStringFilterEditor: any;

  constructor() {
    this.minMaxStringFilterEditor = (
      cell: any,
      onRendered: any,
      success: any,
      cancel: any,
      editorParams: any
    ): HTMLElement => {
      let end: HTMLInputElement;

      const container = document.createElement("div");

      //create and style inputs
      const start = document.createElement("input");
      start.setAttribute("type", "string");
      start.setAttribute("placeholder", "Мин.");
      start.setAttribute("min", "0");
      start.style.padding = "4px";
      start.style.width = "50%";
      start.style.boxSizing = "border-box";

      start.value = cell.getValue();

      const buildValues = (): void => {
        success({
          start: start.value,
          end: end.value,
        });
      };

      const keypress = (e: KeyboardEvent): void => {
        if (e.keyCode === 13) {
          buildValues();
        }

        if (e.keyCode === 27) {
          cancel();
        }
      };

      end = start.cloneNode() as HTMLInputElement;
      end.setAttribute("placeholder", "Макс.");

      start.addEventListener("change", buildValues);
      start.addEventListener("blur", buildValues);
      start.addEventListener("keydown", keypress);

      end.addEventListener("change", buildValues);
      end.addEventListener("blur", buildValues);
      end.addEventListener("keydown", keypress);

      container.appendChild(start);
      container.appendChild(end);

      return container;
    };
  }


  minMaxDateFilterFunction(headerValue:any, rowValue:any, rowData:any, filterParams:any): boolean {
    let arr: string[] = [rowValue, headerValue.start, headerValue.end];

    if (rowValue) {
      if (headerValue.start !== "") {
        if (headerValue.end !== "") {
          arr.sort();
          return rowValue === arr[1];
        } else {
          return rowValue >= headerValue.start;
        }
      } else {
        if (headerValue.end !== "") {
          return rowValue <= headerValue.end;
        }
      }
    }

    return true;
  }
}
