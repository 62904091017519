import { Component } from "@angular/core";

import { date, txHash, currentDate } from "@environments/helper";
import { Router } from "@angular/router";
import { OtherApiService } from "@services/api.service";

@Component({
  selector: "app-insurance-deposit",
  templateUrl: "./distribution-profit.component.html",
  styleUrls: ["../body.css"],
})
export class DistributionProfit {
  constructor(
    private router: Router,
    private otherApiService: OtherApiService
  ) {}

  date = date;
  txHash = txHash;
  loadingTraders = true;
  loadingPayments = true;
  loadingSend = false;
  loadingRate = true;
  selectedAll = true;
  selectedTrader = 0;
  traders: any = [];
  payments: any = [];
  amountAll = 0;
  amountResult = 0;
  rate = 0;
  countTraders = 0;
  percent = 0;
  date_from = currentDate(7);
  date_to = currentDate();
  async ngOnInit() {
    await this.getRate();
    await this.getTraders();
    await this.getPayments();
  }

  updateInfo() {
    this.amountAll = 0;
    this.amountResult = 0;
    this.payments.payments.map((arr: any) => {
      if (arr.checked) {
        this.amountAll += Number(arr.real_amount);
        const usdt = Number(arr.real_amount) / this.rate;
        const profit = usdt * (Number(this.percent) / 100);
        this.amountResult += profit;
      }
    });
    this.amountResult = Number(this.amountResult.toFixed(4));
  }

  onCheckboxChangeAll() {
    this.payments.payments.map((arr: any) => (arr.checked = this.selectedAll));
    this.updateInfo();
  }

  async getRate() {
    this.loadingRate = true;
    const data = await this.otherApiService.get("get-rate");
    this.loadingRate = false;
    if ((data as any).success) {
      this.rate = (data as any).result.price;
    }
    if (this.traders.length > 0) this.updateInfo();
  }

  async getTraders() {
    this.loadingTraders = true;
    const data = await this.otherApiService.getAdmin("get-traders-balances");
    console.log(data);
    this.loadingTraders = false;
    if ((data as any).success) {
      this.traders = (data as any).result;
    }
  }

  async getPayments() {
    this.loadingPayments = true;
    const data = await this.otherApiService.getAdmin("get-payments", {
      date_from: this.date_from,
      date_to: this.date_to,
    });
    console.log(data);
    this.loadingPayments = false;
    if ((data as any).success) {
      this.payments = (data as any).result;
    }
    this.percent = this.payments.percent;
    console.log(this.percent);
    this.updateInfo();
  }

  async send() {
    const data = {
      address_from: this.traders[this.selectedTrader].address,
      amount_usdt: this.amountResult,
    };

    this.loadingSend = true;
    const result = await this.otherApiService.postBody("send-profit", data);
    if ((result as any).success) {
      this.router.navigate(["/tron-history"]);
    }
    console.log(result);
    this.loadingSend = false;
  }
}
