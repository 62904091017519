<div class="add-deposit-block" style="margin-top: 50px">
  <div class="block-deposits">
    <div *ngIf="loading">loading...</div>
    <h3 *ngIf="!loading">Редактирование мерчанта #{{ data.id }}</h3>

    <div class="add-deposit" style="margin-top: 10px" *ngIf="!loading">
      <div class="item">
        <label>Name</label>
        <input type="text" [(ngModel)]="data.name" />
      </div>
      <div class="item">
        <label>Username</label>
        <input type="text" [(ngModel)]="data.username" />
      </div>
      <div class="item">
        <label>Commission</label>
        <input type="text" [(ngModel)]="data.commission" />
      </div>
      <div class="item">
        <label>Address</label>
        <input type="text" [(ngModel)]="data.address" />
      </div>

      <h4 style="margin-top: 10px;">
        Лимиты
        <button class="btn btn-success btn-sm" (click)="showLimit = !showLimit">
          <span *ngIf="showLimit" style="font-size: 12px;">Скрыть</span>
          <span *ngIf="!showLimit" style="font-size: 12px;">Показать</span>
        </button>
        
      </h4>
      <div *ngIf="showLimit">
        <div class="item" *ngFor="let item of limitsKeys; index as i">
          <label>{{ limitsMap[limitsKeys[i]] ?? 'Неизвестное поле'}}</label>
          <input type="text" [(ngModel)]="limits[item]" />
        </div>
      </div>

      <div
      style="color: green; text-align: center; padding: 10px"
      *ngIf="editStatus"
    >
      Сохранено
    </div>
      <button style="margin-top: 10px" *ngIf="loadingEdit">Loading</button>
      <button style="margin-top: 10px" (click)="edit()" *ngIf="!loadingEdit">
        Сохранить информацию
      </button>

      
    </div>
  </div>
</div>
