import { Component } from "@angular/core";
import { OtherApiService } from "@services/api.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "merchant-edit",
  templateUrl: "./merchant-edit.component.html",
  styleUrls: ["../body.css"],
})
export class MerchantEdit {
  constructor(
    private route: ActivatedRoute,
    private otherApiService: OtherApiService
  ) {}

  showLimit = false;
  loading = true;
  loadingEdit = false;
  data: any = [];
  id: any = 0;
  editStatus: any = false;
  JSON = JSON;
  limitsKeys: any = [];
  limits: any = [];
  limitsMap: any = [];

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = Number(params["id"]);
      this.getMerchant();
    });
  }

  async edit() {
    this.editStatus = false;
    this.loadingEdit = true;
    console.log(this.limits);
    this.data["limits"] = this.JSON.stringify(this.limits);

    const data = await this.otherApiService.post("edit-merchant", {
      ...this.data,
    });

    this.loadingEdit = false;

    if ((data as any).success) {
      this.editStatus = true;
    }
  }

  async getMerchant() {
    this.loading = true;
    const data = await this.otherApiService.getAdmin("get-merchant", {
      id: this.id,
    });
    this.loading = false;
    if ((data as any).success) {
      this.data = (data as any).result;
      this.limitsKeys = Object.keys(JSON.parse(this.data.limits));
      this.limits = JSON.parse(this.data.limits);
      this.limitsMap = JSON.parse(this.data.map_limit);
    }
  }
}
