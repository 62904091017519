<div class="add-deposit-block">
  
  <div class="add-deposit">

    <div class="item">
      <label>Оборот <span *ngIf="!loadingRef">{{rev}} руб.</span> <span *ngIf="loadingRef">loading...</span> <span class="btn btn-success" style="margin-left: 20px;" (click)="showData = !showData">Настроить дату</span></label>
      
      <div *ngIf="showData">
        <div class="data_block" style="margin: 0; margin-bottom: 10px; margin-top: 10px;" >
          <div class="item">
            <p>Период от</p>
            <input type="date" [(ngModel)]="date_from" />
          </div>
          <div class="item">
            <p>Период до</p>
            <input type="date" [(ngModel)]="date_to" />
          </div>
        </div>
        <button style="width: 100%;" class="btn btn-primary mr-2 btn-sm" (click)="getWithdrawRev()">
          Оборот за период
        </button>
      </div>
    </div>
    
    <div class="item seleted-block">
      
      <label>Метод <span *ngIf="loadingCommissions">loading...</span>  <span class="btn btn-success" style="margin-left: 5px;" (click)="editMethod = !editMethod">Изменить</span></label>
      
      <div  *ngIf="editMethod">
      
      <select
        [(ngModel)]="selectedMethodId"
        [ngModelOptions]="{ standalone: true }"
      >
        <option *ngFor="let option of commissions; let i = index" [value]="i">
          {{ option.name }} ({{ option.key }})
        </option>
      </select>
      
   

    <div class="item" *ngIf="!loadingCommissions">
      <label>Комиссия %</label>
      <input
        [(ngModel)]="commissions[selectedMethodId].value"
        type="text"
        placeholder="Комиссия"
      />
    </div>

    <button (click)="saveComissions()" *ngIf="!loadingSend">Сохранить</button>
    <button *ngIf="loadingSend">Loading</button>
  </div>
  </div>
  </div>

  <div style="margin-top: 10px; margin-bottom: 10px">
    
    
    <div class="data_block_withdraw" style="text-align: center; padding-top: 10px;border-radius: 10px;">
      <button class="btn btn-primary mr-2 btn-sm" (click)="setStatus()">
        Все
      </button>
      <button class="btn btn-warning mr-2 btn-sm" (click)="setStatus('pending')">
        Pending
      </button>
      <button
        class="btn btn-success mr-2 btn-sm"
        (click)="setStatus('confirmed')"
      >
        Confirmed
      </button>
      <button class="btn btn-error mr-2 btn-sm" (click)="setStatus('canceled')">
        Canceled
      </button>
      <div class="data_block">
      <select
      style="color: #000; margin-left: 10px; margin-right: 10px"
      [(ngModel)]="selectedMethodIdFilter"
      [ngModelOptions]="{ standalone: true }"
      (change)="getWithdraw(true)"
    >
      <option style="color: #000 !important" [value]="null">Все способы</option>
      <option
        style="color: #000"
        *ngFor="let option of commissions; let i = index"
        [value]="i"
      >
        {{ option.name }} ({{ option.key }})
      </option>
    </select>
      <input
      [(ngModel)]="searchId"
      type="text"
      placeholder="Поиск По ID"
      style="
        background: #fff;
        border-radius: 5px;
        color: #000;
      "
    />
  </div>
    
  </div>
    <button style="width: 100%;" class="btn btn-primary mr-2 btn-sm" (click)="getWithdraw(true)">
      Найти
    </button>
  </div>

  <div class="pagination">
    <span style="width: auto; padding-left: 5px; padding-right: 5px;" (click)="setPage(page-1)" *ngIf="page != 1">Prev</span>
    <span *ngFor="let option of getVisiblePages(); let i = index" [ngClass]="{ 'active-pagination': option == page }" (click)="setPage(option)" >{{option}}</span>
    <span style="width: auto; padding-left: 5px; padding-right: 5px;" (click)="setPage(page+1)" *ngIf="page != lastPage && lastPage > 0">Next</span>
  </div>


  <div class="block-deposits">
    <div *ngIf="loadingWithdraw" style="text-align: center">Loading...</div>

    <table *ngIf="!loadingWithdraw" class="table">
      <thead>
        <tr>
          <td style="width: 50px">ID</td>
          <td>Способ</td>
          <td>Карта</td>
          <td>Сумма</td>
          <td>Статус</td>
          <td>Создан</td>
          <td>Взят в обработку</td>
          <td>Обработан</td>
          <td>Коммен трейдер</td>
          <td>Коммен БК</td>
          <td>Файлы</td>
          <td>Трейдер</td>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of withdrawals; index as i"
          [ngClass]="{ new_message: item.trader_view === false, yallow_tr: item.prioritys }"
       
        >
          <td style="width: 50px">{{ item.id }}</td>
          <td>
            {{ item.payment_method?.toLowerCase() }}
          </td>
          <td>
            {{ item.payee_detail }}
            <br />
            {{ item.bank }}
          </td>
          <td>
            {{ item.amount }}
            <br />
            <span
              style="
                background: green;
                padding: 3px;
                border-radius: 5px;
                font-size: 12px;
              "
              >{{ ((item.commission - 1) * 100).toFixed(1) }}%</span
            >
          </td>
          <td>
            <button
              *ngIf="!item.loading_cancel && item.status == 'pending'"
              class="btn btn-error mr-2 btn-sm"
              (click)="cancel(i)"
            >
              Cancel
            </button>
            <div *ngIf="item.loading_cancel">loading...</div>
            {{ item.status }}
            <button
              *ngIf="!item.loading_callback && item.status != 'pending'"
              class="btn btn-primary mr-2 btn-sm"
              (click)="resendCallBack(i)"
            >
              callback
            </button>
            <div *ngIf="item.loading_callback">loading...</div>
          </td>
          <td>{{ convertDateTimeZone(item.created_at) }}</td>
          <td>{{ convertDateTimeZone(item.taken_at) }}</td>
          <td>{{ convertDateTimeZone(item.processed_at) }}</td>
          <td>
            {{ item.comment_trader }}
          </td>
          <td>
            {{ item.comment }}
          </td>
          <td>
            <div
              style="margin-top: 10px"
              *ngFor="let file of JSON.parse(item.files); index as i2"
            >
              <a target="_blank" [href]="apiUrl + '/files/' + file">
                {{ shortString(file) }}
              </a>
            </div>
          </td>
          <td>{{ item.username }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
