import { Component } from '@angular/core';

import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { FormControl } from '@angular/forms'

import { PaymentsApiService } from '@services/api.service'
import { TabulatorAdditionalService } from '@services/tabulator-additional.service'
import { environment } from '@environments/environment';
import { getCookie } from '@environments/helper';

@Component({
  selector: 'app-review-panel',
  templateUrl: './review-panel.component.html',
  styleUrls: ['./review-panel.component.css']
})


export class ReviewPanelComponent {

  realAmountInput: any = new FormControl;

  constructor(
      private paymentsApiService: PaymentsApiService,
      private tabulatorAdditional: TabulatorAdditionalService
  ) {
  }

  ngOnInit() {
      this.drawTable();
  }
  

  rowContextMenu = [
      {
          label:'Подтвердить платёж',
          action: async (e: any, row: any) => {
              const realAmount = this.realAmountInput.value

              this.realAmountInput.clear;

              const id = row.getCell('id').getValue()

              const success = await this.paymentsApiService.confirm(id, realAmount);

              if ( success ){
                row.delete();
              }
          }
      },
      {
          label:'Отклонить платёж',
          action: async (e: any, row: any) => {
              const id = row.getCell('id').getValue()

              const success = await this.paymentsApiService.cancel(id);

              if ( success ){
                row.delete();
              }
          }
      }
  ]
  
  private drawTable(): void {
    var table = document.createElement('div');

    new Tabulator(table, {
      reactiveData:true,
      pagination:true,
      paginationMode: "local",
      paginationSize:15,
      paginationSizeSelector:[15, 50, 100, 500, true],
      ajaxURL: environment.apiUrl + "/payments/getpayments?filter=canceled",
      ajaxConfig: {
				headers: {
					'Authorization': JSON.parse(getCookie('json_token') || '{}')['token']
				}
			},
      columns: [
        {
          title: "ID",
          field:"id",
          headerFilter:"input"
        },
        {
          title: "Сумма",
          field:"amount",
          headerFilter:"input"
        },
        {
          title: "Номер карты",
          field:"card_number",
          headerFilter:"input"
        },
        {
          title: "ФИО",
          field:"name",
          headerFilter:"input"
        },
        {
          title: "Валюта",
          field:"currency",
          headerFilter:"input"
        },
        {
          title: "Дата создания",
          field:"creation_time",
          headerFilter:this.tabulatorAdditional.minMaxStringFilterEditor,
          headerFilterFunc:this.tabulatorAdditional.minMaxDateFilterFunction,
        },
        {
          title: "Комментарий",
          field:"comment",
          headerFilter:"input"
        }
      ],
      rowContextMenu: this.rowContextMenu,
      layout: 'fitData',
      height: "100%"
    });
    document.getElementById('main-table')?.appendChild(table);
  }

}
