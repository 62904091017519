import { Component } from "@angular/core";

import { date, txHash } from "@environments/helper";
import { Router } from "@angular/router";

import { OtherApiService } from "@services/api.service";

@Component({
  selector: "app-insurance-deposit",
  templateUrl: "./distribution-bk.component.html",
  styleUrls: ["../body.css"],
})
export class DistributionBk {
  constructor(
    private router: Router,
    private otherApiService: OtherApiService
  ) {}

  date = date;
  txHash = txHash;
  loadingTraders = true;
  loadingMerchants = true;
  loadingSend = false;
  selectedAll = true;
  selectedMerchant = 0;
  traders: any = [];
  tradersLocal: any = [];
  merchants: any = [];
  amountAll = 0;
  percent = 100;

  ngOnInit() {
    this.getTraders();
    this.getTradersLocal();
    this.getMerchants();
  }

  updateInfo() {
    this.amountAll = 0;
    this.traders.map((arr: any) => {
      if (arr.checked) {
        this.amountAll += arr.amount_usdt_send;
      }
    });
  }

  onPercentChange(i: number) {
    this.traders[i].amount_usdt_send = Number(
      (this.traders[i].amount_usdt * (this.traders[i].percent / 100)).toFixed(4)
    );
    this.updateInfo();
  }
  onCheckboxChangeAll() {
    this.traders.map((arr: any) => (arr.checked = this.selectedAll));
    this.updateInfo();
  }

  onPercentChangeAll() {
    if (this.percent > 100) this.percent = 100;
    this.traders.map((arr: any) => {
      arr.percent = this.percent;
      arr.amount_usdt_send = Number(
        (arr.amount_usdt * (this.percent / 100)).toFixed(4)
      );
    });
    this.updateInfo();
  }
  onCheckboxChange() {
    this.updateInfo();
  }

  async getTraders() {
    this.loadingTraders = true;
    const data = await this.otherApiService.getAdmin("get-traders-balances");
    console.log(data);
    this.loadingTraders = false;
    if ((data as any).success) {
      this.traders = (data as any).result;
      this.updateInfo();
    }
  }

  async getTradersLocal() {
    const data = await this.otherApiService.getAdmin("get-traders");
    console.log(data);
    if ((data as any).success) {
      this.tradersLocal = (data as any).result;
    }
  }

  getTraderUsername(address: any) {
    let trader: any = null;
    this.tradersLocal.filter((item: any) => {
      if (item.address?.toLowerCase() == address?.toLowerCase()) {
        trader = item;
      }
    });

    if (!trader) return null;

    return `(${trader.username})`;
  }

  async getMerchants() {
    this.loadingMerchants = true;
    const data = await this.otherApiService.getAdmin("get-merchants");
    console.log(data);
    this.loadingMerchants = false;
    if ((data as any).success) {
      this.merchants = (data as any).result;
    }
  }
  async send() {
    let traders: any = [];
    this.traders.map((arr: any) => {
      if (arr.checked) {
        traders.push({
          address: arr.address,
          amount_usdt: arr.amount_usdt_send - 1,
        });
      }
    });
    const data = {
      to: this.merchants[this.selectedMerchant].address,
      addresses: traders,
    };
    console.log(data);
    this.loadingSend = true;
    const result = await this.otherApiService.postBody("send-bk", data);
    console.log(result);
    if ((result as any).success) {
      this.router.navigate(["/tron-history"]);
    }

    this.loadingSend = false;
  }
}
