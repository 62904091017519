<div class="add-deposit-block" style="margin-top: 50px">
  <h3 style="text-align: left; width: 100%">
    <span style="padding-right: 10px">Мерчанты</span>

    <button routerLink="/merchants/create" class="btn btn-success mr-2 btn-sm">
      Создать
    </button>
    <button routerLink="/users-banned" style="color:#000;" class="btn btn-warning mr-2 btn-sm">
      Заблокированные пользователи
    </button>
  </h3>

  <div class="block-deposits">
    <div *ngIf="loading">loading...</div>

    <table *ngIf="!loading" class="table">
      <thead>
        <tr>
          <td>ID</td>
          <td>Merchant ID</td>
          <td>Name</td>
          <td>Earnings</td>
          <td>Commission</td>
          <td>Address</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data" [routerLink]="['/merchants/', item.id]">
          <td>{{ item.id }}</td>
          <td>{{ item.merchant_id }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.earnings }}</td>
          <td>{{ item.commission }}</td>
          <td>{{ item.address }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
