<main>
  <form (ngSubmit)="createTrader()" [formGroup]="traderForm">
    <div class="input-container">
      <input
        required
        formControlName="username"
        placeholder="Имя пользователя"
      />
    </div>

    <div class="input-container">
      <input required formControlName="password" placeholder="Пароль" />
    </div>

    <div class="input-container">
      <input required formControlName="name" placeholder="ФИО" />
    </div>

    <div class="input-container">
      <input required formControlName="phone" placeholder="Телефон" />
    </div>
    <button>Добавить трейдера</button>
  </form>
  <div style="padding: 10px; text-align: center">
    <span *ngIf="loading">loading...</span>
  </div>
  <div
    style="height: 30rem; min-width: 30rem"
    id="main-table"
    ng-bind-html="checkWithdraw"
  ></div>
</main>
