import { Component } from "@angular/core";

import { TabulatorFull as Tabulator } from "tabulator-tables";
import { FormControl } from "@angular/forms";

import { PaymentsApiService } from "@services/api.service";
import { TabulatorAdditionalService } from "@services/tabulator-additional.service";
import { DateTime } from "luxon"; // зависимость табулятора
import { environment } from "@environments/environment";

import { getCookie } from "@environments/helper";
window.DateTime = DateTime;

@Component({
  selector: "app-payments-list",
  templateUrl: "./payments-list.component.html",
  styleUrls: ["./payments-list.component.css"],
})
export class PaymentsListComponent {
  realAmountInput: any = new FormControl();

  constructor(
    private paymentsApiService: PaymentsApiService,
    private tabulatorAdditional: TabulatorAdditionalService
  ) {}

  ngOnInit() {
    this.drawTable();
  }

  async payment() {
    this.loading = true;
    const success = await this.paymentsApiService.confirm(this.id, this.amount);
    this.loading = false;

    if (success) {
      //this.cell?.getRow().delete();
      this.cell.setValue("true");
    }
    this.showModal = false;
  }

  cancel() {
    this.id = 0;
    this.cell = null;
    this.amount = null;
    this.showModal = false;
  }

  id: any = 0;
  cell: any = null;
  amount: any = null;
  showModal = false;
  loading = false;

  rowContextMenu = [
    {
      label: "Подтвердить платёж",
      action: async (e: any, row: any) => {
        const realAmount = this.realAmountInput.value;

        this.realAmountInput.clear;

        const data: any = row.getData();

        const success = await this.paymentsApiService.confirm(
          data.id,
          realAmount
        );

        console.log(row);

        if (success) {
          row.delete();
        }
      },
    },
    {
      label: "Отклонить платёж",
      action: async (e: any, row: any) => {
        const data: any = row.getData();
        const success = await this.paymentsApiService.cancel(data.id);

        if (success) {
          row.delete();
        }
      },
    },
  ];

  private drawTable(): void {
    var table = document.createElement("div");

    new Tabulator(table, {
      persistence: {
        filter: true,
        sort: true,
      },
      filterMode: "remote",
      sortMode: "remote",
      pagination: true,
      paginationMode: "remote",
      paginationSize: 300,
      paginationSizeSelector: [5, 10, 15, 20, 50, 100, 5000, 100000, true],
      ajaxURL: environment.apiUrl + "/payments/get",
      ajaxConfig: {
        headers: {
          Authorization: JSON.parse(getCookie("json_token") || "{}")["token"],
        },
      },
      columns: [
        {
          title: "ID",
          field: "id",
          headerFilter: "input",
          headerFilterFunc: "=",
          headerSort: false,
        },
        {
          title: "ID от мерчанта",
          field: "payment_id",
          headerFilter: "input",
        },
        {
          title: "ID пользователя",
          field: "user_id",
          headerFilter: "input",
        },
        {
          title: "Сумма",
          field: "amount",
          headerFilter: "number",
          bottomCalc: "sum",
          bottomCalcParams: {
            precision: 1,
          },
        },
        {
          title: "Изменённая сумма",
          field: "real_amount",
          headerFilter: "number",
          bottomCalc: "sum",
          bottomCalcParams: {
            precision: 1,
          },
        },
        {
          title: "Номер карты",
          field: "card_number",
          headerFilter: "input",
        },
        {
          title: "ФИО",
          field: "card_name",
          headerFilter: "input",
        },
        {
          formatter: (cell) => {
            let color = "#fff";
            const data: any = cell.getData();
            if (data.status == "canceled") color = "red";
            if (data.status == "true") color = "green";
            if (data.status == "false") color = "orange";

            const check =
              data.status != "true"
                ? "<span ><img src='/assets/svg/check2.svg' class='check_pay' width='20px'></span>"
                : "";

            return `<span style="color: ${color};"> ${data.status}</span> ${check}`;
          },
          cellClick: async (e, cell: any) => {
            const data: any = cell.getData();

            if (data.status == "true") return;

            this.amount = this.realAmountInput.value;
            this.id = data.id;
            this.cell = cell;

            this.showModal = true;
          },
          title: "Статус",
          field: "status",
          headerFilter: "list",
          headerFilterParams: {
            values: {
              true: "true",
              false: "false",
              review: "review",
              canceled: "canceled",
              "": "any",
            },
            clearable: true,
          },
        },
        {
          title: "Дата создания",
          field: "creation_time",
          formatter: "datetime",
          formatterParams: {
            inputFormat: "yyyy-MM-dd HH:mm:ss.u",
            outputFormat: "yyyy-MM-dd HH:mm:ss",
            invalidPlaceholder: "(invalid date)",
          },
          headerFilter: "input", // временно
          // headerFilter:this.tabulatorAdditional.minMaxStringFilterEditor,
          // headerFilterFunc:this.tabulatorAdditional.minMaxDateFilterFunction,
        },
        {
          title: "Валюта",
          field: "currency",
          headerFilter: "input",
        },
        {
          title: "Комментарий",
          field: "comment",
          headerFilter: "input",
        },
      ],
      rowContextMenu: this.rowContextMenu,
      layout: "fitData",
      height: "100%",
    });
    document.getElementById("main-table")?.appendChild(table);
  }
}
