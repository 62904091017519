<div class="container">
  <h2>Уведомления</h2>
  <div *ngIf="loadingNotifs" style="text-align: center">Loading...</div>
  <main *ngIf="!loadingNotifs">
    <table class="table">
      <thead>
        <tr>
          <td style="width: 100px">#</td>
          <td style="width: 100px">Дата и время</td>
          <td>Сообщение</td>
        </tr>
      </thead>
      <tbody>
        <tr
          class="tr"
          *ngFor="let item of items"
          [ngClass]="{ new_message: item['view'] === false }"
        >
          <td style="width: 100px">{{ item["id"] }}</td>
          <td style="width: 100px">{{ date(item["created_at"]) }}</td>
          <td>{{ item["message"] }}</td>
        </tr>
      </tbody>
    </table>
  </main>
</div>
