import { Component } from "@angular/core";

import { date, txHash } from "@environments/helper";

import { TradersApiService, OtherApiService } from "@services/api.service";

@Component({
  selector: "app-insurance-deposit",
  templateUrl: "./tron-history.component.html",
  styleUrls: ["../body.css"],
})
export class TronHistory {
  constructor(
    private tradersApiService: TradersApiService,
    private otherApiService: OtherApiService
  ) {}

  date = date;
  txHash = txHash;
  loading = true;
  history: any = [];
  ngOnInit() {
    this.getHistory();
  }

  async getHistory() {
    this.loading = true;
    const data = await this.otherApiService.getAdmin("get-tron-history");
    console.log(data);
    this.loading = false;
    if ((data as any).success) {
      this.history = (data as any).result;
    }
  }
}
